import StoreItemsModule from "@/config/store/StoreItemsModule"

import { add as dateAdd } from "date-fns"
import { currentDateInTimeZone } from "@/helpers/common"

const BASE_URI = "/reservations"
const today = currentDateInTimeZone()

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"],
  withFilters: {
    payment_status: [],
    shop_ids: [],
    car_class_ids: [],
    ota_ids: [],
    start_date: today,
    end_date: dateAdd(today, { days: 30 }),
    search: "",
    order: {
      field: "reserved_at",
      type: "desc"
    }
  },
  withPagination: true
})

export default store
