<template lang="pug">
  .reservations
    AppOverlayLoader(:state="loading")
    TopBar(
      @change="fetchReservations"
    )
    ReservationsTable(
      :items="reservations"
      :order="{ ...filters.order }"
      :loading="loading"
      @change-order="changeOrder"
    )
    .reservations-table-options
      AppPagination(
        :current-page="pagination.current_page"
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import reservationsModule from "@/config/store/reservations"
  import shopMatchingModule from "@/config/store/matching/shop"
  import carClassMatchingModule from "@/config/store/matching/car_class"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  const shopMatchingsMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatching",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatching",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const reservationsMixin = withStoreModule(reservationsModule, {
    name: "reservations",
    readers: { reservations: "items", reservationsLoading: "loading", filters: "filters", pagination: "pagination" },
    actions: { fetchReservations: "FETCH_ITEMS" },
    mutations: { setFilters: "SET_FILTERS", setPagination: "SET_PAGINATION_DATA" }
  })

  export default {
    computed: {
      loading({ shopsLoading, carClassesLoading, reservationsLoading }) {
        return shopsLoading || carClassesLoading || reservationsLoading
      }
    },

    components: {
      TopBar: () => import("./TopBar"),
      ReservationsTable: () => import("./ReservationsTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [shopMatchingsMixin, carClassMatchingMixin, reservationsMixin],

    mounted() {
      this.fetchShops()
      this.fetchCarClasses()
      this.fetchReservations()
    },

    methods: {
      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchReservations()
      },

      changeOrder(orderOptions) {
        const orderObject = orderOptions[0]
        const { field, type } = this.filters.order

        if (field === orderObject.field && type === orderObject.type) return

        this.setFilters({ order: orderObject })
        this.setPagination({ ...this.pagination, current_page: 1 })
        this.fetchReservations()
      }
    }
  }
</script>

<style lang="sass" scoped>
  .reservations
    position: relative
</style>
